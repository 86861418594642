import {
	BOOKING_DURATION_UNIT_HOURS,
	SITE_PRODUCT_SELECTION,
	SITE_PRODUCT_SELECTION_TYPE_LOWEST,
	STRIPE_JS_SDK_URL,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	EcommerceProduct,
	EcommerceProductVariant,
	EcommerceProductVariantQuantity,
	EcommerceVariantPrice,
	ElasticSearchVariant,
	ElasticSearchResult,
} from '@zyro-inc/site-modules/types';

export const SPACING_BETWEEN_COLUMNS_MIN = 0;
export const SPACING_BETWEEN_COLUMNS_MAX = 50;
export const DEFAULT_COLUMN_COUNT_DESKTOP = 3;
export const DEFAULT_COLUMN_COUNT_MOBILE = 1;

export const productsPerPageByColumnCount: Record<number, number> = {
	6: 18,
	5: 15,
	4: 12,
	3: 9,
	2: 6,
	1: 3,
} as const;

const isBookingProductDurationInHours = (product: EcommerceProduct) => (
	product?.variants[0].booking_event?.length_unit === BOOKING_DURATION_UNIT_HOURS
);

export function getBookingDuration(product: EcommerceProduct): number {
	return product?.variants[0].booking_event?.length || 0;
}

export const getFormattedBookingDuration = (product: EcommerceProduct, translations: Record<string, string>) => {
	// not using toPrecision because bigger numbers that have more than 3 digits are displayed with exponential notation
	const roundNumberToTwoDecimals = (number: number) => Math.round(number * 100) / 100;

	if (isBookingProductDurationInHours(product)) {
		return `${roundNumberToTwoDecimals(getBookingDuration(product) / 1000 / 60 / 60)} ${translations.hourShort}`;
	}

	return `${roundNumberToTwoDecimals(getBookingDuration(product) / 1000 / 60)} ${translations.minuteShort}`;
};

export const isProductPriceRangeShown = (product: EcommerceProduct | ElasticSearchResult): boolean => {
	if (product[SITE_PRODUCT_SELECTION] !== SITE_PRODUCT_SELECTION_TYPE_LOWEST) {
		return false;
	}

	// TODO: remove type any
	return !(product.variants as any).every(
		(item: any, _: any, arr: Array<EcommerceProductVariant | ElasticSearchVariant>) => {
			const arrayPrices = (arr[0] as any)?.prices?.[0] ?? arr[0];
			const itemsPrices = item?.prices?.[0] ?? item;

			const firstPrice = arrayPrices.sale_amount ?? arrayPrices.amount;
			const priceToCompareWith = itemsPrices.sale_amount ?? itemsPrices.amount;

			return firstPrice === priceToCompareWith;
		},
	);
};

export const getVariantQuantity = ({
	variantsQuantity,
	variantId,
}: {
	variantsQuantity: Array<EcommerceProductVariantQuantity | ElasticSearchVariant>,
	variantId: string,
}) => variantsQuantity.find(
	(variant) => variant.id === variantId,
)?.inventory_quantity || 0;

export const getFullProductQuantity = ({
	product,
	variantsQuantity,
}: {
	product: EcommerceProduct | ElasticSearchResult,
	variantsQuantity: Array<EcommerceProductVariantQuantity | ElasticSearchVariant>,
}) => (product.variants as any).reduce((acc: number, curr: EcommerceProductVariantQuantity | ElasticSearchVariant) => acc
	+ getVariantQuantity({
		variantsQuantity,
		variantId: curr.id,
	}), 0);

export const getIsProductInStock = ({
	product,
	variantsQuantity,
}: {
	product: EcommerceProduct | ElasticSearchResult,
	variantsQuantity: Array<EcommerceProductVariantQuantity | ElasticSearchVariant>,
}) => {
	if (!product.variants[0].manage_inventory) {
		return true;
	}

	const fullProductQuantity = getFullProductQuantity({
		product,
		variantsQuantity,
	});

	return fullProductQuantity > 0;
};

export const getLowestPriceVariant = ({ variants }: {variants: Array<EcommerceVariantPrice | ElasticSearchVariant>}) => variants.reduce(
	(acc, curr) => ((acc.sale_amount || acc.amount) <= (curr.sale_amount || curr.amount) ? acc : curr),
);

export const getScrollToTopTriggerProductIndex = (columnCount: number) => {
	// Based on such logic: 2 columns - 2nd row, 3 columns - 3rd row, 4 columns - 4th row and etc
	const targetRowToShowScrollToTop = columnCount;

	// Calculates which product index should show scroll to top based on desired row start as mentioned above
	return (targetRowToShowScrollToTop - 1) * columnCount;
};

export const loadStripeSdk = async () => {
	if (document.getElementById('stripejs')) {
		// Stripe.js is already loaded
		await new Promise<void>((resolve) => {
			const existingScript = document.getElementById('stripejs');

			// @ts-ignore-next-line
			if (existingScript?.getAttribute('src') === STRIPE_JS_SDK_URL && window.Stripe) {
				resolve();
			} else {
				existingScript?.addEventListener('load', () => resolve());
			}
		});
	} else {
		const stripejs = document.createElement('script');

		stripejs.setAttribute('src', STRIPE_JS_SDK_URL);
		stripejs.setAttribute('id', 'stripejs');
		document.head.appendChild(stripejs);

		// Wait until the script is loaded
		await new Promise<void>((resolve, reject) => {
			stripejs.addEventListener('load', () => resolve());
			stripejs.addEventListener('error', () => reject(new Error('Failed to load Stripe.js')));
		});
	}
};
