<template>
	<SiteElementSearchBar
		:placeholder-text="props.data.placeholderText"
		:no-results-text="props.data.noResultsText"
		:fill-color="props.data.fillColor"
		:fill-color-hover="props.data.fillColorHover"
		:text-and-icon-color="props.data.textAndIconColor"
		:text-and-icon-color-hover="props.data.textAndIconColorHover"
		:font-family="props.data.fontFamily"
		:border-color="props.data.borderColor"
		:border-color-hover="props.data.borderColorHover"
		:border-radius="props.data.borderRadius"
		:is-loading="isSearchResultsLoading"
		:result-item-hover-color="props.data.resultItemHoverColor"
		:results="formattedSearchResults"
		:search-term="searchTerm"
		:site-id="siteId"
		:translations="ecommerceTranslations"
		@update:search-term="updateSearchTerm({ newValue: $event })"
		@is-dropdown-open="toggleSearchDropdown"
	/>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import SiteElementSearchBar from '@zyro-inc/site-modules/components/SiteElementSearchBar.vue';
import { useProductSearch } from '@zyro-inc/site-modules/use/useProductSearch';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

import { useSearchElementDropdown } from '@zyro-inc/site-modules/utils/useSearchElementDropdown';

const props = defineProps({
	data: {
		type: Object,
		required: true,
	},
	blockId: {
		type: String,
		required: true,
	},
});

const {
	siteId,
	ecommerceShoppingCart,
} = useSiteGlobal();

const {
	isSearchResultsLoading,
	searchTerm,
	formattedSearchResults,
	updateSearchTerm,
} = useProductSearch();

const ecommerceTranslations = computed(() => ecommerceShoppingCart.value?.translations || {});

const { toggleSearchDropdown } = useSearchElementDropdown({
	blockId: props.blockId,
});
</script>
