import {
	EcommerceBookingAvailability,
	EcommerceBookingTimeSlot,
	EcommerceCart,
	EcommerceCartUpdatePayload,
	EcommerceCollection,
	EcommerceCreateCartPayload,
	EcommerceProduct,
	EcommerceProductSeoSettingsData,
	EcommerceProductVariantQuantity,
	EcommerceRegion,
	EcommerceShippingOption,
	EcommerceShippingProviderType,
	EcommerceStoreSettings,
} from '@zyro-inc/site-modules/types';
import { getBatchedArrays } from '@zyro-inc/site-modules/utils/getBatchedArrays';

const ECOMMERCE_API = `${import.meta.env.VITE_ECOMMERCE_API_URL}/store`;

export default {
	async getStoreProducts(storeId: string, options: {
		sort?: string,
		offset?: number,
		limit?: number,
		collectionId?: string,
		toDate?: string,
	} = {}): Promise<{products: EcommerceProduct[], count: number}> {
		const {
			sort,
			offset,
			limit,
			collectionId,
			toDate,
		} = options;

		const searchParams = new URLSearchParams(sort);

		if (typeof offset === 'number' && limit) {
			searchParams.set('offset', offset.toString());
			searchParams.set('limit', limit.toString());
		}

		if (collectionId) {
			searchParams.set('collection_ids[]', collectionId);
		}

		if (toDate) {
			searchParams.set('to_date', toDate);
		}

		const response = await fetch(`${ECOMMERCE_API}/${storeId}/products?${searchParams.toString()}`);
		const data = await response.json();

		return data;
	},

	async getStoreProductsByIds(storeId: string, productIds: string[]): Promise<EcommerceProduct[]> {
		const productIdsInBatches = getBatchedArrays(productIds || [], 1);

		const fetchPromises = productIdsInBatches?.map(async (batch) => {
			const searchParams = new URLSearchParams();

			batch.forEach((id) => searchParams.append('ids[]', id));
			const response = await fetch(`${ECOMMERCE_API}/${storeId}/products?${searchParams.toString()}`);

			return response.json();
		});

		const results = await Promise.allSettled(fetchPromises);

		const fulfilledResults = results
			.filter((result) => result.status === 'fulfilled')
			.map((result) => (result as PromiseFulfilledResult<any>).value);

		return fulfilledResults.flatMap(({ products }) => products);
	},

	async getStoreProductSeo(storeId: string): Promise<{products: EcommerceProductSeoSettingsData[]}> {
		const response = await fetch(`${ECOMMERCE_API}/${storeId}/products/seo-settings`);
		const data = await response.json();

		return data;
	},

	async getProductByIdOrSlug({
		storeId,
		productId,
		slug,
	}: {
		storeId: string,
		productId?: string | number,
		slug?: string
	}): Promise<EcommerceProduct> {
		const queryParams = slug ? '?field=slug' : '';
		const response = await fetch(`${ECOMMERCE_API}/${storeId}/products/${productId || slug}${queryParams}`);
		const data = await response.json();

		return data.product;
	},

	async getVariantsQuantity(storeId: string, productIds: string[]): Promise<EcommerceProductVariantQuantity[]> {
		const suffix = productIds.map((id) => `product_ids[]=${id}`).join('&');
		const response = await fetch(`${ECOMMERCE_API}/${storeId}/variants?fields=inventory_quantity&${suffix}`);
		const data = await response.json();

		return data.variants;
	},

	async getCheckoutUrl({
		items,
		successUrl,
		cancelUrl,
		checkoutUrl,
		locale,
		storeId,
	}: {
		items: Array<{ id: string, quantity: number }>,
		successUrl: string,
		cancelUrl: string,
		checkoutUrl: string,
		locale: string,
		storeId: string,
	}): Promise<string> {
		const response = await fetch(`${ECOMMERCE_API}/${storeId}/checkout`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'omit',
			body: JSON.stringify({
				items,
				successUrl,
				cancelUrl,
				checkoutUrl,
				locale,
				timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			}),
		});
		const data = await response.json();

		if (response.ok) {
			return data.url;
		}

		throw (data);
	},

	async getTimeSlots(bookingId: string, date: string): Promise<EcommerceBookingTimeSlot> {
		const response = await fetch(`${ECOMMERCE_API}/time-slots`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'omit',
			body: JSON.stringify({
				booking_event_id: bookingId,
				time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				date,
			}),
		});
		const data = await response.json();

		if (response.ok) {
			return data.slots;
		}

		throw (data);
	},

	async getAvailability({
		bookingId,
		fromDate,
		toDate,
	}: {
		bookingId: string,
		fromDate: string,
		toDate: string,
	}): Promise<EcommerceBookingAvailability> {
		const response = await fetch(`${ECOMMERCE_API}/availability`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'omit',
			body: JSON.stringify({
				booking_event_id: bookingId,
				time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				from_date: fromDate,
				to_date: toDate,
			}),
		});
		const data = await response.json();

		if (response.ok) {
			return data.disabled_dates;
		}

		throw (data);
	},

	async getCategories(storeId: string): Promise<{collections: EcommerceCollection[]}> {
		const response = await fetch(`${ECOMMERCE_API}/${storeId}/collections`);
		const data = await response.json();

		return data;
	},

	async getStoreSettings(storeId: string): Promise<EcommerceStoreSettings> {
		const response = await fetch(`${ECOMMERCE_API}/${storeId}/settings`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'omit',
		});
		const data = await response.json();

		if (response.ok) {
			return data;
		}

		throw (data);
	},

	async getStoreRegions(storeId: string): Promise<EcommerceRegion[]> {
		const response = await fetch(`${ECOMMERCE_API}/${storeId}/regions`);
		const data: {
			regions: {
				id: string,
				countries: {
					iso_2: string,
					provinces: {
						iso_2: string
					}[]
				}[]
			}[]
		} = await response.json();

		return data.regions.map((region) => ({
			id: region.id,
			countries: region.countries.map((country) => ({
				countryCode: country.iso_2,
				provinces: country.provinces.map((province: any) => province.iso_2),
			})),
		}));
	},

	async getCartShippingOptions(cartId: string): Promise<EcommerceShippingOption[]> {
		const response = await fetch(`${ECOMMERCE_API}/shipping-options/${cartId}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});
		const data: {
			shipping_options: {
				id: string,
				name: string,
				provider_id: EcommerceShippingProviderType,
				amount: number | null
			}[]
		} = await response.json();

		return data.shipping_options.map((option) => ({
			id: option.id,
			displayName: option.name,
			provider: option.provider_id,
			amount: option.amount || 0,
		}));
	},

	async getCart(cartId: string): Promise<EcommerceCart> {
		const response = await fetch(`${ECOMMERCE_API}/carts/${cartId}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});
		const data = await response.json();

		return data.cart;
	},

	async updateCart(id: string, cartData: EcommerceCartUpdatePayload): Promise<EcommerceCart> {
		const response = await fetch(`${ECOMMERCE_API}/carts/${id}`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				billing_address: {
					address_1: cartData.billingAddress.line1,
					city: cartData.billingAddress.city,
					name: cartData.billingAddress.name,
					phone: cartData.billingAddress.phone,
					postal_code: cartData.billingAddress.postal_code,
					country_code: cartData.billingAddress.country,
					province_code: cartData.billingAddress.state,
				},
				shipping_address: {
					address_1: cartData.shippingAddress.line1,
					city: cartData.shippingAddress.city,
					name: cartData.shippingAddress.name,
					phone: cartData.shippingAddress.phone,
					postal_code: cartData.shippingAddress.postal_code,
					country_code: cartData.shippingAddress.country,
					province_code: cartData.shippingAddress.state,
				},
				region_id: cartData.regionId,
			}),
		});
		const data = await response.json();

		return data.cart;
	},

	// TODO: update with correct params, https://hostingers.atlassian.net/browse/BE-769
	async initiatePaymentSession(cartId: string): Promise<EcommerceCart> {
		const response = await fetch(`${ECOMMERCE_API}/carts/${cartId}/payment-session`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				// provider_id: providerId,
				express_checkout: true,
			}),
		});
		const data = await response.json();

		return data.cart;
	},

	async createCart({
		items,
		successUrl,
		cancelUrl,
		locale,
		storeId,
	}: EcommerceCreateCartPayload): Promise<EcommerceCart> {
		const response = await fetch(`${ECOMMERCE_API}/carts`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				store_id: storeId,
				items,
				success_url: successUrl,
				cancel_url: cancelUrl,
				time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				locale,
			}),
		});
		const data = await response.json();

		return data.cart;
	},
};
