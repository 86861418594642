<template>
	<div class="grid-gallery-masonry">
		<div
			v-for="(imagesColumn, columnIndex) in imagesByColumns"
			:key="columnIndex"
			class="grid-gallery-masonry__column"
		>
			<slot
				v-for="image in imagesColumn"
				:index="image.originalIndex"
				name="image"
				:image="image"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ComposedGalleryImage } from '@zyro-inc/site-modules/types';

const props = defineProps<{
	images: ComposedGalleryImage[],
	columnCount: number,
	columnGap: number,
}>();

const columnGapPx = computed(() => `${props.columnGap}px`);
const imagesByColumns = computed(() => props.images.reduce((acc, image, imageIndex) => acc.map(
	(column, columnIndex) => (columnIndex === imageIndex % props.columnCount ? [
		...column,
		{
			...image,
			originalIndex: imageIndex,
		},
	] : column),
), Array(props.columnCount).fill([])));
</script>

<style lang="scss">
.grid-gallery-masonry {
	display: grid;
	grid-template-columns: repeat(v-bind('props.columnCount'), auto);
	grid-column-gap: v-bind(columnGapPx);

	&__column {
		display: flex;
		gap: v-bind(columnGapPx);
		flex-direction: column;
	}
}
</style>
