<template>
	<ModalLayout
		v-if="isEcommerceStoreCreated"
		ref="modalRef"
	>
		<ModalContent
			:title="modalContent.title"
			:text="modalContent.text"
			:button-text="modalContent.buttonText"
			:qa-selector="modalContent.modalType"
			@handle-close="modalRef.handleCloseModalAction()"
		/>
	</ModalLayout>
</template>

<script setup lang="ts">
import {
	Ref,
	onMounted,
	ref,
} from 'vue';
import { EcommerceProductType } from '@zyro-inc/site-modules/types';
import ModalContent from '@zyro-inc/site-modules/components/ecommerce/modals/partials/ModalContent.vue';
import ModalLayout from '@zyro-inc/site-modules/components/ecommerce/modals/partials/ModalLayout.vue';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';

interface Props {
	translations: Record<string, string>,
}

const props = withDefaults(defineProps<Props>(), {
	translations: () => ({}),
});

const {
	isEcommerceStoreCreated,
	setShoppingCartItems,
} = useEcommerceGlobal();

const modalContent = ref({
	title: props.translations.checkoutModalThanksForOrder,
	text: props.translations.checkoutModalOrderReceived,
	buttonText: props.translations.gotIt,
	modalType: 'success-order',
});
const modalRef = ref(null) as Ref<any>;

onMounted(() => {
	const params = new URLSearchParams(window.location.search);
	const productType = params.get('product');

	if (productType !== EcommerceProductType.BOOKING) {
		setShoppingCartItems([]);
	}

	if (productType === EcommerceProductType.BOOKING) {
		modalContent.value = {
			...modalContent.value,
			text: props.translations.checkoutModalSuccessfullyBooked,
			modalType: 'success-orderbooking',
		};
	} else if (productType === EcommerceProductType.DONATION) {
		modalContent.value = {
			...modalContent.value,
			title: props.translations.checkoutModalThanksForDonation,
			text: props.translations.checkoutModalDonationReceived,
			modalType: 'success-orderdonation',
		};
	} else if (productType === EcommerceProductType.DIGITAL) {
		modalContent.value = {
			...modalContent.value,
			text: props.translations.checkoutModalDigitalProductEmail,
			modalType: 'success-orderdigital',
		};
	}
});
</script>
