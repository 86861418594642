<template>
	<div
		v-if="isLoading || showSuccessMessage || isError"
		ref="postSubmitRef"
		class="submit-message"
		:style="submitElementCSSVars"
		:class="`submit-message--${theme}`"
	>
		<ZyroLoader
			v-if="isLoading"
			color="var(--grid-button-primary-background-color)"
		/>
		<div
			v-else-if="showSuccessMessage && !isError"
			class="success-message__heading"
		>
			{{ successMessage }}
			<div v-if="isInPreviewMode">
				Note: Forms submitted in preview mode won't appear in Form submissions.
			</div>
		</div>
		<div v-if="isError && !isLoading">
			<div class="submit-message__heading">
				Something went wrong
			</div>
			<div>
				Please try again later
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import { THEME_LIGHT } from '@zyro-inc/site-modules/components/elements/form/constants';

import {
	ref,
	watch,
	computed,
} from 'vue';

type Props = {
	successMessage?: string;
	isError: boolean;
	isLoading: boolean;
	showSuccessMessage: boolean;
	theme: 'light' | 'dark';
	hasBorderWidth: boolean;
	isInPreviewMode?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	successMessage: '',
	isError: false,
	isLoading: false,
	showSuccessMessage: false,
	theme: THEME_LIGHT,
	hasBorderWidth: false,
});

const postSubmitRef = ref<HTMLElement | null>(null);

const submitElementCSSVars = computed(() => ({
	'--submit-element-border': props.hasBorderWidth ? '' : 'var(--color-gray)',
}));

watch(postSubmitRef, () => {
	if (postSubmitRef.value) {
		postSubmitRef.value.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	}
});

</script>

<style lang="scss" scoped>
/* stylelint-disable custom-property-pattern */
.submit-message {
	position: absolute;
	top: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	place-content: center;
	place-items: center;
	width: 100%;
	height: 100%;
	padding: 24px;
	text-align: center;
	border: 1px solid var(--submit-element-border);

	&--dark {
		color: $color-light;
	}

	&__heading {
		margin-bottom: var(--formSpacing);
		white-space: break-spaces;
	}
}

.success-message {
	&__heading {
		white-space: break-spaces;
	}
}
</style>
