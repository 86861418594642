<script setup lang="ts">
import { CLASS_BUILDER_PREVIEW } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	onBeforeUnmount,
	onMounted,
	ref,
} from 'vue';

const thresholdMarkerRef = ref<HTMLElement | null>(null);
const thresholdPositionFromWindowTop = ref(0);
const elementToWatch = ref<HTMLElement | Window | null>(null);

const props = defineProps<{
	isInEditor?: boolean
	isPreviewMode?: boolean
	marginOfError: number
}>();

const emit = defineEmits<{
	'update-visibility': [boolean]
}>();

const getScrollYPosition = () => (props.isPreviewMode ? (elementToWatch.value as HTMLElement)?.scrollTop : window.scrollY);

const updateScrollToTopVisibility = (): void => {
	const scrollYPosition = getScrollYPosition();
	const scrollThresholdPosition = scrollYPosition + props.marginOfError;

	if (thresholdPositionFromWindowTop.value <= scrollThresholdPosition) {
		// User is scrolling down and passed the threshold
		emit('update-visibility', true);
	} else if (thresholdPositionFromWindowTop.value > scrollThresholdPosition) {
		// User is scrolling up and above the threshold, hide the button
		emit('update-visibility', false);
	}
};

const getElementToWatchScrollOn = () => {
	if (props.isPreviewMode) {
		elementToWatch.value = document.querySelector(`.${CLASS_BUILDER_PREVIEW}`) as HTMLElement;
	} else {
		elementToWatch.value = window;
	}
};

onMounted(() => {
	if (props.isInEditor && !props.isPreviewMode) {
		return;
	}

	getElementToWatchScrollOn();
	// Calculate the threshold position from the top of the window
	thresholdPositionFromWindowTop.value = Math.round((thresholdMarkerRef.value?.getBoundingClientRect().top || 0) + getScrollYPosition());

	elementToWatch.value?.addEventListener('scroll', updateScrollToTopVisibility);
});

onBeforeUnmount(() => {
	elementToWatch.value?.removeEventListener('scroll', updateScrollToTopVisibility);
});
</script>

<template>
	<div ref="thresholdMarkerRef" />
</template>
