import {
	computed,
	ref,
} from 'vue';
import StoreApi from '@zyro-inc/site-modules/api/StoreApi';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';

import { EcommerceProduct } from '@zyro-inc/site-modules/types';

const RELATED_PRODUCTS_PER_PAGE = 4;
const ALL_PRODUCTS_CATEGORY_ID = 'all-products';

export const useRelatedProducts = ({
	blockId,
	storeId,
}: {storeId: string, blockId: string}) => {
	const {
		setIsLoading,
		setIsLoaded,
		updateVariantsQuantity,
	} = useEcommerceGlobal({
		blockId,
	});

	const relatedProductsPage = ref<number>(1);
	const isRelatedProductsLoading = ref(false);
	const totalRelatedProductsCount = ref(0);
	const relatedProducts = ref<EcommerceProduct[]>([]);
	const relatedProductsPageCount = computed(() => (
		totalRelatedProductsCount.value
			? Math.ceil(totalRelatedProductsCount.value / RELATED_PRODUCTS_PER_PAGE)
			: 0));

	const { pageData } = useSiteGlobal();

	const fetchRelatedProducts = async ({
		page,
		categoryId,
		toDate = pageData?.value.buildDate ? new Date(Number.parseInt(pageData.value.buildDate, 10)).toISOString() : undefined,
	}: {
		page?: number;
        categoryId?: string;
        toDate?: string;
    }) => {
		isRelatedProductsLoading.value = true;
		relatedProductsPage.value = page || 1;

		// !IMPORTANT to reset isLoaded for animations
		setIsLoading(true);
		setIsLoaded(false);

		try {
			const pageOffset = (relatedProductsPage.value - 1) * RELATED_PRODUCTS_PER_PAGE;
			const collectionId = categoryId === ALL_PRODUCTS_CATEGORY_ID ? undefined : categoryId;

			const productsData = await StoreApi.getStoreProducts(storeId, {
				offset: pageOffset,
				limit: RELATED_PRODUCTS_PER_PAGE,
				collectionId,
				sort: collectionId ? 'order=ASC&sort_by=collection_order' : undefined,
				toDate,
			});

			await updateVariantsQuantity(productsData.products);

			totalRelatedProductsCount.value = productsData.count;

			relatedProducts.value = productsData.products;
		} catch (error) {
			console.error(error);
		} finally {
			isRelatedProductsLoading.value = false;

			// !IMPORTANT to reset isLoaded for animations
			setIsLoading(false);
			setIsLoaded(true);
		}
	};

	return {
		relatedProductsPage,
		isRelatedProductsLoading,
		totalRelatedProductsCount,
		relatedProducts,
		relatedProductsPageCount,
		fetchRelatedProducts,
	};
};
