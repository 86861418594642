<template>
	<!--
		As user can embed anything to grid embed it can greatly impact his page speed in addition to skewing
		the overall statistics of the user site speed, so we use IntersectionObserver to render GridEmbed only
		when it has been intersected
	-->
	<GridEmbed
		:id="id"
		ref="gridEmbedRef"
		:should-render="isObserved"
		:srcdoc="srcdoc"
		:is-sand-boxed="isInPreviewMode"
		:height="data.settings?.styles.height"
		:height-mobile="data.settings?.styles.heightMobile"
	/>
</template>

<script setup lang="ts">
import {
	ref,
	onMounted,
} from 'vue';
import { SiteElement } from '@hostinger/builder-schema-validator/schema/schemaTypes';

import GridEmbed from '@zyro-inc/site-modules/components/elements/embed/GridEmbed.vue';
import { constructSrcdoc } from '@zyro-inc/site-modules/utils/constructSrcdoc';
import { useObserver } from '@zyro-inc/site-modules/use/useObserver';

interface Props {
	data: SiteElement;
	id: string;
	isInPreviewMode: boolean;
}

const props = defineProps<Props>();

const gridEmbedRef = ref<HTMLElement | null>(null);
const { isObserved } = useObserver(gridEmbedRef);
const srcdoc = ref<string | undefined>();

onMounted(() => {
	srcdoc.value = constructSrcdoc(props.data.content ?? '', props.id);
});
</script>
