import {
	ref,
	onMounted,
} from 'vue';
import { CHECKOUT_ERROR_CODES } from '@zyro-inc/site-modules/constants/ecommerce';

import StoreApi from '@zyro-inc/site-modules/api/StoreApi';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import {
	getBeginCheckoutEventPayload,
	googleTagManagerEvent,
} from '@zyro-inc/site-modules/utils/googleTagManager';
import {
	getQuantifiedCartItems,
	getSuccessUrl,
	prepareCartItemsForCheckoutInitiation,
} from '@zyro-inc/site-modules/utils/ecommerce/cartData';

export const useEcommerce = () => {
	const { openEcommerceModal } = useEcommerceModal();
	const {
		meta,
		ecommerceShoppingCart,
		isSiteEngine,
		hasGoogleAdsApp,
	} = useSiteGlobal();

	let storeId = '';
	const cancelUrl = ref('');

	onMounted(() => {
		storeId = meta.value?.ecommerceStoreId;
		cancelUrl.value = `${window.location.origin}${window.location.pathname}`;
	});

	async function createCheckoutUrl(checkoutItems) {
		const updatedCheckoutItems = prepareCartItemsForCheckoutInitiation(checkoutItems);
		const quantifiedProductList = getQuantifiedCartItems(updatedCheckoutItems);

		const locale = ecommerceShoppingCart.value?.lang || 'en';

		let checkoutUrl = await StoreApi.getCheckoutUrl({
			items: quantifiedProductList,
			cancelUrl: cancelUrl.value,
			successUrl: getSuccessUrl(updatedCheckoutItems),
			...(!isSiteEngine.value && {
				checkoutUrl: `${window.location.origin}/checkout`,
			}),
			locale,
			storeId,
		});

		checkoutUrl = new URL(checkoutUrl);
		checkoutUrl.searchParams.set('lang', locale);

		return checkoutUrl.toString();
	}

	async function initiateCheckout(checkoutItems) {
		if (!storeId) {
			openEcommerceModal('EcommerceCheckoutFailed');

			return;
		}

		try {
			if (hasGoogleAdsApp.value) {
				googleTagManagerEvent('begin_checkout', getBeginCheckoutEventPayload(checkoutItems));
			}

			const checkoutUrl = await createCheckoutUrl(checkoutItems);

			window.location.assign(checkoutUrl);
		} catch (error) {
			if (error.code === CHECKOUT_ERROR_CODES.INSUFFICIENT_INVENTORY) {
				openEcommerceModal('EcommerceOutOfStock');
			} else if (error.code === CHECKOUT_ERROR_CODES.BOOKING_UNAVAILABLE) {
				openEcommerceModal('EcommerceBookingUnavailable');
			} else {
				openEcommerceModal('EcommerceCheckoutFailed');
			}
		}
	}

	return {
		initiateCheckout,
		storeId,
		cancelUrl,
		getSuccessUrl,
		createCheckoutUrl,
	};
};
