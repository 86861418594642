<script setup lang="ts">
import {
	ANCHOR_TAG,
	DIV_TAG,
	DATA_ATTRIBUTE_ANIMATION_ROLE,
	DATA_ATTRIBUTE_SELECTOR,
	DATA_ATTRIBUTE_SELECTOR_IMAGE,
	DATA_ATTRIBUTE_ANIMATION_ROLE_IMAGE,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

import {
	MOBILE_BLOCK_WIDTH,
	DESKTOP_BLOCK_WIDTH,
} from '@zyro-inc/site-modules/components/blocks/layout/constants';
import {
	computed,
	onMounted,
	onUnmounted,
	ref,
} from 'vue';

type TypeName = typeof DIV_TAG | typeof ANCHOR_TAG;

interface Props {
	src: string
	alt?: string
	href?: string | null
	preventDrag?: boolean
	sizes?: string
	srcset?: string
	tagName?: TypeName
	target?: string | null
	rel?: string | null
	isLightboxEnabled?: boolean
	isUnstyled?: boolean
	resetMobilePosition?: boolean
	elementWidth?: number
	elementHeight?: number
	mobileBorderRadius?: string | number
	desktopBorderRadius?: string | number
	cropCssVars?: Record<string, string> | null
	isOverflowVisible?: boolean
	isEager?: boolean
	isMobileImage?: boolean
	isInBuilder?: boolean
	isSvg?: boolean
	shapeMaskSource?: string | null
	isInPreviewMode?: boolean
	overlayOpacity?: number
}

const props = withDefaults(defineProps<Props>(), {
	href: null,
	tagName: DIV_TAG,
	target: null,
	rel: null,
	resetMobilePosition: true,
	elementWidth: 0,
	elementHeight: 0,
	mobileBorderRadius: 0,
	desktopBorderRadius: 0,
	cropCssVars: null,
	shapeMaskSource: null,
	overlayOpacity: 0,
});

const emit = defineEmits<{
	'image-load': [],
	'image-click': [MouseEvent]
}>();

// If image height is < 0 mobile values are not yet mapped
const imageRef = ref<HTMLImageElement | null>(null);
const isLoaded = ref(false);

const isMobileLayoutImage = computed(() => props.elementHeight !== null && props.elementHeight > 0 && props.isMobileImage);

const mobileWidthCSSVar = computed(() => {
	if (props.isInBuilder) {
		return '100%';
	}

	if (props.isInPreviewMode) {
		return `${props.elementWidth}px`;
	}

	return (isMobileLayoutImage.value ? `${(props.elementWidth * 100) / MOBILE_BLOCK_WIDTH}vw` : '100%');
});
const mobileHeightCSSVar = computed(() => {
	if (props.isInBuilder) {
		return 'auto';
	}

	if (props.isInPreviewMode) {
		return `${props.elementHeight}px`;
	}

	return (isMobileLayoutImage.value ? `${(props.elementHeight * 100) / MOBILE_BLOCK_WIDTH}vw` : 'auto');
});

const imageCSSVars = computed(() => ({
	'--overflow': props.isOverflowVisible ? 'visible' : null,
	...props.cropCssVars,
}));

const smallDesktopWidthCSSVar = computed(() => {
	if (props.isInBuilder) {
		return '100%';
	}

	return `${(props.elementWidth * 100) / DESKTOP_BLOCK_WIDTH}vw`;
});

const smallDesktopHeightCSSVar = computed(() => {
	if (props.isInBuilder) {
		return '100%';
	}

	return `${(props.elementHeight * 100) / DESKTOP_BLOCK_WIDTH}vw`;
});

const overlayOpacityCSSVar = computed(() => (props.overlayOpacity ? `rgba(0, 0, 0, ${props.overlayOpacity / 100})` : null));
const desktopBorderRadiusCSSVar = computed(() => {
	if (props.shapeMaskSource) {
		return null;
	}

	if (props.desktopBorderRadius) {
		return props.desktopBorderRadius;
	}

	if (props.mobileBorderRadius) {
		return props.mobileBorderRadius;
	}

	return null;
});
const mobileBorderRadiusCSSVar = computed(() => {
	if (props.shapeMaskSource) {
		return null;
	}

	if (props.mobileBorderRadius) {
		return props.mobileBorderRadius;
	}

	if (props.desktopBorderRadius) {
		return props.desktopBorderRadius;
	}

	return null;
});
const shapeMaskSourceCssVar = computed(() => (props.shapeMaskSource && `url('${props.shapeMaskSource}')`));

const handleImageLoadEvent = () => {
	emit('image-load');
	isLoaded.value = true;
};

onMounted(() => {
	if (imageRef.value) {
		// Cached images do not emit load event, however they have complete property set to true
		if (imageRef.value?.complete) {
			handleImageLoadEvent();

			return;
		}

		imageRef.value.addEventListener('load', handleImageLoadEvent);
	}
});

onUnmounted(() => {
	if (imageRef.value) {
		imageRef.value.removeEventListener('load', handleImageLoadEvent);
	}
});
</script>

<template>
	<Component
		:is="tagName"
		v-qa="'grid-image'"
		:href="href"
		:target="target"
		:rel="rel"
		:title="alt"
		:style="imageCSSVars"
		class="image"
		:class="{
			'image--zoom': isLightboxEnabled,
			'image--grid': !isUnstyled,
			'image--unstyled': isUnstyled,
			'image--link': tagName === ANCHOR_TAG,
			'loaded': isLoaded
		}"
		:[DATA_ATTRIBUTE_SELECTOR]="DATA_ATTRIBUTE_SELECTOR_IMAGE"
		:[DATA_ATTRIBUTE_ANIMATION_ROLE]="DATA_ATTRIBUTE_ANIMATION_ROLE_IMAGE"
		@click="$emit('image-click', $event)"
	>
		<img
			ref="imageRef"
			v-qa="'builder-gridelement-gridimage'"
			:alt="alt"
			:src="src"
			:srcset="srcset"
			:sizes="sizes"
			:height="elementHeight"
			:width="elementWidth"
			:loading="isEager? 'eager' : 'lazy'"
			:class="{
				'image__image--unstyled': isUnstyled,
				'image__image--cropped': !!cropCssVars,
				'image__image': !isUnstyled,
				'image__image--reset-m-position': resetMobilePosition,
				'image__image--svg': isSvg
			}"
			v-on="{
				drag: preventDrag ? (e: MouseEvent) => e.preventDefault() : () => null,
				dragstart: preventDrag ? (e: MouseEvent) => e.preventDefault() : () => null,
			}"
		>
		<slot />
	</Component>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.image {
	overflow: var(--overflow, hidden);
	border-radius: v-bind(desktopBorderRadiusCSSVar);
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-mask-image: v-bind(shapeMaskSourceCssVar);
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-mask-size: cover;
	mask-image: v-bind(shapeMaskSourceCssVar);
	mask-size: 100% 100%;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: v-bind(overlayOpacityCSSVar);
		z-index: 1;
	}

	&--grid {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;

		@media screen and (min-width: $media-mobile) and (max-width: $media-desktop-editor) {
			width: v-bind(smallDesktopWidthCSSVar);
			max-width: 100%;
			height: v-bind(smallDesktopHeightCSSVar);
		}
	}

	&--unstyled {
		// Fill element with image
		display: flex;
	}

	&--link {
		transition: filter 0.2s ease;

		&:hover {
			filter: contrast(0.8);
		}
	}

	&--zoom {
		cursor: zoom-in;
	}

	&__image {
		// <img /> size is controlled by parent element (which is controlled by grid)
		display: block;
		object-fit: cover;

		&--svg {
			object-fit: cover;
		}

		// When size is not controlled by grid
		&,
		&--unstyled {
			width: 100%;
			height: 100%;
		}

		&--cropped {
			position: absolute; // absolute is needed for cropping to work properly
			top: var(--desktop-top);
			left: var(--desktop-left);
			width: var(--desktop-width);
			height: var(--desktop-height);
			transform: scale(var(--desktop-scale));
			transform-origin: 0 0;
		}
	}
}

@include site-engine-mobile {
	.image {
		border-radius: v-bind(mobileBorderRadiusCSSVar);

		&--grid {
			width: 100%;
			height: 100%;
		}

		&__image {
			&--cropped {
				top: var(--mobile-top);
				left: var(--mobile-left);
				width: var(--mobile-width);
				height: var(--mobile-height);
				transform: scale(var(--mobile-scale));
			}

			&--reset-m-position {
				position: static;
				width: 100%;
				height: auto;
			}
		}
	}
}

@media screen and (max-width: $media-mobile-editor) {
	.image {
		width: v-bind(mobileWidthCSSVar);
		max-width: 100%;
		height: v-bind(mobileHeightCSSVar);
	}
}
</style>
