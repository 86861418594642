import {
	EcommerceProduct,
	GoogleTagManagerEventPayloadMap,
} from '@zyro-inc/site-modules/types';

export const googleTagManagerEvent = <K extends keyof GoogleTagManagerEventPayloadMap>(
	eventName: K,
	payload?: GoogleTagManagerEventPayloadMap[K],
): void => window.gtag('event', eventName, payload);

export const getAddToCartEventPayload = (product: EcommerceProduct) => {
	const {
		currency,
		amount,
	} = product.variants[0].prices[0];

	const {
		title,
		seo_settings: seoSettings,
	} = product;

	return {
		value: amount,
		currency: currency.code.toUpperCase(),
		items: [
			{
				title,
				slug: seoSettings.slug,
			},
		],
	};
};

export const getBeginCheckoutEventPayload = (products: EcommerceProduct[]) => {
	const finalAmount = products.reduce((total, product) => {
		const { amount } = product.variants[0].prices[0];

		return total + amount;
	}, 0);

	const items = products.map((product) => ({
		title: product.title,
		slug: product.seo_settings.slug,
	}));

	const { currency } = products[0].variants[0].prices[0];

	return {
		value: finalAmount,
		currency: currency.code.toUpperCase(),
		items,
	};
};

export const getPurchaseEventPayload = (products: EcommerceProduct[]) => {
	const finalAmount = products.reduce((total, product) => {
		const { amount } = product.variants[0].prices[0];

		return total + amount;
	}, 0);

	const items = products.map((product) => ({
		title: product.title,
		slug: product.seo_settings.slug,
	}));

	const { currency } = products[0].variants[0].prices[0];

	return {
		value: finalAmount,
		currency: currency.code.toUpperCase(),
		items,
	};
};
