const EMBED_STYLE_RESET = `
<style>
	html {
		overflow: hidden;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
 	}

 	iframe {
 		max-width: 100%;
 	}
</style>
`;
const getEmbedHeightObserverScript = (id: string) => `
<script>
	const heightObserver = new ResizeObserver(([{ contentRect }]) => {
		window.parent.postMessage({ action: 'iframeHeightUpdated', height: contentRect.height, id: '${id}' }, '*');
	});

	heightObserver.observe(document.documentElement);
</script>
`;

export const constructSrcdoc = (content: string, id: string): string => {
	const temporaryElement = document.createElement('div');

	temporaryElement.innerHTML = content;

	// TS doesn't allow using spread on HTMLCollection, even though it works.
	// Tried adding `"downlevelIteration": true` to tsconfig, but it didn't help. So ignoring the error as it works.
	// @ts-ignore
	const extractStylesScripts = [...temporaryElement.children].reduce((acc, curr) => {
		if (curr.tagName === 'STYLE') {
			return {
				...acc,
				styles: `${acc.styles} ${curr.outerHTML}`,
			};
		}

		if (curr.tagName === 'SCRIPT') {
			return {
				...acc,
				scripts: `${acc.scripts} ${curr.outerHTML}`,
			};
		}

		return {
			...acc,
			html: `${acc.html} ${curr.outerHTML}`,
		};
	}, {
		styles: EMBED_STYLE_RESET,
		scripts: getEmbedHeightObserverScript(id),
		html: '',
	});

	return `<!DOCTYPE html>
	<head>${extractStylesScripts.styles}</head>
	<body>${extractStylesScripts.html}${extractStylesScripts.scripts}</body>
</html>`;
};
