import {
	SiteBlock,
	SiteBlockEcommerceProduct,
	SiteBlockStickyBar,
	SiteLanguages,
} from '@hostinger/builder-schema-validator/schema/schemaTypes';
import { BLOCK_STICKY_BAR_ID } from '@zyro-inc/site-modules/constants/siteModulesConstants';

export const getAllSiteElements = (
	{ languages }: {languages: SiteLanguages},
) => Object.values(languages).flatMap((language) => Object.values(language.elements));

export const getStickyBarItems = ({ languages }: {languages: SiteLanguages}) => Object.values(languages).flatMap(
	(language) => (language.blocks[BLOCK_STICKY_BAR_ID] as SiteBlockStickyBar)?.items ?? [],
);

export const sortLanguagesArray = (languages: any[]) => {
	if (!languages.toSorted) return languages;

	const {
		withPositions,
		reorderedLanguages,
	} = languages.reduce((acc, lang) => {
		if (Number.isInteger(lang.orderPosition) && lang.orderPosition as number >= 0) {
			acc.withPositions.push(lang);
		} else {
			acc.reorderedLanguages.push(lang);
		}

		return acc;
	}, {
		withPositions: [],
		reorderedLanguages: [],
	});

	withPositions.toSorted((a: any, b: any) => a.orderPosition - b.orderPosition).forEach((language: any) => {
		reorderedLanguages.splice(language.orderPosition, 0, language);
	});

	return reorderedLanguages;
};

export const getAllEcommerceBlocksByType = (types: string[], siteLanguages: SiteLanguages) => {
	const langBlocks = Object.keys(siteLanguages).map(
		(lang) => siteLanguages[lang].blocks,
	) as Record<string, SiteBlock>[];

	return Object.fromEntries(langBlocks.flatMap(
		(blocks) => Object.entries(blocks)
			.filter(([, data]) => types.includes(data.type)),
	)) as Record<string, SiteBlockEcommerceProduct>;
};
