import {
	SitePages,
	SitePage,
} from '@hostinger/builder-schema-validator/schema/schemaTypes';
import {
	COOKIE_EXPRESS_CHECKOUT_ENABLED,
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { getCookie } from '@zyro-inc/site-modules/utils/cookies';

export const isProductPage = (pageType: string) => [
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
].includes(pageType);

export const getIsDynamicProductPageType = (page: SitePage) => page?.type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT;

export const getIsDynamicProductPageEnabled = (
	{ defaultLocalePages }: {defaultLocalePages?: SitePages},
) => Object.values(defaultLocalePages || {}).some(getIsDynamicProductPageType);

export const getIsExpressCheckoutEnabled = (): boolean => {
	const expressCheckoutEnabledCookie = getCookie(COOKIE_EXPRESS_CHECKOUT_ENABLED);

	return expressCheckoutEnabledCookie === '1';
};
