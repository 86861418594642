import {
	ref,
	computed,
} from 'vue';

import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import {
	ElasticSearchVariant,
	ElasticSearchResult,
	SiteElementSearchItem,
} from '@zyro-inc/site-modules/types';

import {
	getIsProductInStock,
	isProductPriceRangeShown,
	getLowestPriceVariant,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { formatPrice } from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';

import { search } from '@zyro-inc/site-modules/api/PublicApi';

import { SEARCH_INDICE_PRODUCTS } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { SITE_PRODUCT_SELECTION_TYPE_LOWEST } from '@zyro-inc/site-modules/constants/ecommerce';

import { debounce } from '@zyro-inc/site-modules/utils/debounce';

const TIMEOUT_MS_SEARCH_DEBOUNCE = 400;

export const getFormattedSearchResult = ({
	result,
	ecommerceTranslations,
}: {result: ElasticSearchResult, ecommerceTranslations: Record<string, string>}) => {
	const variant: ElasticSearchVariant = result.site_product_selection === SITE_PRODUCT_SELECTION_TYPE_LOWEST
		? getLowestPriceVariant({
			variants: result.variants,
		}) as ElasticSearchVariant
		: result.variants[0];
	const {
		amount,
		sale_amount: saleAmount,
		currency_decimal_digits: currencyDecimalDigits,
		currency_template: currencyTemplate,
		manage_inventory: isInventoryManaged,
	} = variant;
	const currency = {
		decimal_digits: currencyDecimalDigits,
		template: currencyTemplate,
	};
	const href = `/product-redirect/${result.id}`;
	const pricePrefix = amount && isProductPriceRangeShown(result) ? `${ecommerceTranslations.from} ` : '';
	const price = amount ? `${pricePrefix}${formatPrice({
		amount: saleAmount || amount,
		currency,
	})}` : '';
	const oldPrice = saleAmount ? formatPrice({
		amount,
		currency,
	}) : null;
	const isInStock = !isInventoryManaged || (!!amount && getIsProductInStock({
		product: result,
		variantsQuantity: result.variants,
	}));

	return {
		id: result.id,
		thumbnail: result.thumbnail,
		title: result.title,
		href,
		price,
		oldPrice,
		isInStock,
	};
};

export const useProductSearch = () => {
	const {
		meta,
		ecommerceShoppingCart,
	} = useSiteGlobal();

	const isSearchResultsLoading = ref(false);
	const searchTerm = ref('');
	const searchResults = ref<Array<ElasticSearchResult> | null>(null);

	const storeId = computed(() => meta.value.ecommerceStoreId);

	const ecommerceTranslations = computed(() => ecommerceShoppingCart.value?.translations || {});

	const formattedSearchResults = computed<Array<SiteElementSearchItem> | null>(() => {
		if (!searchResults.value) {
			return null;
		}

		return searchResults.value.map((result: ElasticSearchResult) => getFormattedSearchResult({
			result,
			ecommerceTranslations: ecommerceTranslations.value,
		}));
	});

	const searchHandler = debounce(async () => {
		isSearchResultsLoading.value = true;

		if (searchTerm.value && storeId.value) {
			searchResults.value = await search({
				query: searchTerm.value,
				indices: [SEARCH_INDICE_PRODUCTS],
				storeId: storeId.value,
			});
		} else {
			searchResults.value = null;
		}

		isSearchResultsLoading.value = false;
	}, TIMEOUT_MS_SEARCH_DEBOUNCE);

	const updateSearchTerm = ({ newValue } : { newValue: string }) => {
		searchTerm.value = newValue;
		searchHandler();
	};

	return {
		updateSearchTerm,
		searchHandler,
		isSearchResultsLoading,
		searchTerm,
		searchResults,
		formattedSearchResults,
	};
};
