<template>
	<div class="grid-gallery">
		<template v-if="isMasonryLayout">
			<Masonry
				v-if="isMasonryLayout"
				class="grid-gallery-masonry--desktop"
				:images="props.images"
				:column-count="props.columnCount"
				:column-gap="props.columnGap"
			>
				<template #image="{ image, index }">
					<GridImage
						:key="`${index}${image.src}`"
						:alt="image.alt"
						:src="props.loadImages ? image.src : ''"
						:srcset="image.srcset"
						:sizes="image.sizes"
						:element-width="image.width"
						:element-height="image.height"
						is-unstyled
						:is-lightbox-enabled="props.isLightboxEnabled"
						:reset-mobile-position="false"
						prevent-drag
						is-eager
						@image-click="emit('image-click', index)"
						@image-load="emit('image-load')"
					/>
				</template>
			</Masonry>
			<Masonry
				v-if="isMasonryLayout"
				class="grid-gallery-masonry--mobile"
				:images="props.images"
				:column-count="props.mobileColumnCount"
				:column-gap="props.mobileColumnGap"
			>
				<template #image="{ image, index }">
					<GridImage
						:key="`${index}${image.src}`"
						:alt="image.alt"
						:src="props.loadImages ? image.src : ''"
						:srcset="image.srcset"
						:sizes="image.sizes"
						:element-width="image.width"
						:element-height="image.height"
						is-unstyled
						:is-lightbox-enabled="props.isLightboxEnabled"
						:reset-mobile-position="false"
						prevent-drag
						is-eager
						@image-click="emit('image-click', index)"
						@image-load="emit('image-load')"
					/>
				</template>
			</Masonry>
		</template>
		<div
			v-else
			v-qa="`grid-gallery-grid`"
			class="grid-gallery-grid"
		>
			<div
				v-for="(image, index) in images"
				:key="`${index}${image.src}`"
				class="grid-gallery-grid__image-wrapper"
			>
				<GridImage
					:key="`${index}${image.src}`"
					class="grid-gallery-grid__image"
					:alt="image.alt"
					:src="props.loadImages ? image.src : ''"
					:srcset="image.srcset"
					:sizes="image.sizes"
					:element-width="image.width"
					:element-height="image.height"
					:is-lightbox-enabled="props.isLightboxEnabled"
					:reset-mobile-position="false"
					prevent-drag
					is-eager
					@image-click="emit('image-click', index)"
					@image-load="emit('image-load')"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import GridImage from '@zyro-inc/site-modules/components/elements/image/GridImage.vue';
import Masonry from '@zyro-inc/site-modules/components/Masonry.vue';
import { ComposedGalleryImage } from '@zyro-inc/site-modules/types';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
	columnCount: number,
	columnGap: number,
	mobileColumnCount: number,
	mobileColumnGap: number,
	images: ComposedGalleryImage[],
	isLightboxEnabled?: boolean,
	isMasonryLayout?: boolean,
	loadImages?: boolean,
}>(), {
	isLightboxEnabled: false,
	isMasonryLayout: false,
	loadImages: true,
});

const emit = defineEmits<{
	'image-click': [number]
	'image-load': [],
}>();

const columnGapPx = computed(() => `${props.columnGap}px`);
const mobileColumnGapPx = computed(() => `${props.mobileColumnGap}px`);
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.grid-gallery-masonry {
	&--mobile {
		display: none;
	}
}

.grid-gallery-grid {
	display: grid;
	grid-template-columns: repeat(v-bind('props.columnCount'), 1fr);
	grid-gap: v-bind(columnGapPx);

	&__image-wrapper {
		position: relative;

		// Used to retain identical height for all Grid images
		padding-top: 100%;

		// Increased specificity over `GridImage` styles
		.grid-gallery-grid__image {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}

@include site-engine-mobile {
	.grid-gallery-masonry {
		&--desktop {
			display: none;
		}

		&--mobile {
			display: grid;
		}
	}

	.grid-gallery-grid {
		grid-template-columns: repeat(v-bind('props.mobileColumnCount'), 1fr);
		grid-gap: v-bind(mobileColumnGapPx);
	}
}
</style>
