import { SHOPPING_CART_STORAGE_KEY } from '@zyro-inc/site-modules/constants/ecommerce';
import {
	EcommerceBookingEvent,
	EcommerceCartPayloadCustomField,
	EcommerceCartPayloadItem,
	EcommerceProduct,
	EcommerceProductType,
	EcommerceProductVariant,
} from '@zyro-inc/site-modules/types';
import { getIsSameCustomField } from '@zyro-inc/site-modules/utils/ecommerce/productUtils';

type ShoppingCartStorageData = {
	expiry: number;
	payload: EcommerceProduct[];
}

type PreparedCartItem = Omit<EcommerceProduct, 'custom_fields' | 'variants'> & {
	custom_field_values?: EcommerceCartPayloadCustomField[];
	variants: (Omit<EcommerceProductVariant, 'booking_event'> & {
		booking_event?: EcommerceBookingEvent & {
			time_slot?: string;
			time_zone?: string;
			length?: number;
			length_unit?: string;
			date?: string;
		};
	})[];
};

// Wrapping the function call in a Promise with setTimeout ensures it is executed as a macrotask.
// This is important because localStorage operations are synchronous but not part of the microtasks queue.
// Using setTimeout guarantees that the localStorage operation is correctly delayed and handled in the macrotask queue.
export const getCartDataFromStorage = (): Promise<EcommerceProduct[]> => new Promise((resolve) => {
	setTimeout(() => {
		const storageValue = window.localStorage.getItem(SHOPPING_CART_STORAGE_KEY);

		if (!storageValue) {
			return resolve([]);
		}

		const data = JSON.parse(storageValue) as ShoppingCartStorageData;

		if (!data.payload.length) {
			return resolve([]);
		}

		if (Date.now() > data.expiry) {
			window.localStorage.removeItem(SHOPPING_CART_STORAGE_KEY);

			return resolve([]);
		}

		return resolve(data.payload);
	}, 0);
});

export const getExistingProductWithCustomField = (
	quantifiedProducts: EcommerceCartPayloadItem[],
	productToMatch: PreparedCartItem,
) => quantifiedProducts.find(
	(searchedProduct) => {
		const isSameProduct = searchedProduct.variant_id === productToMatch.variants[0].id;

		if (searchedProduct.custom_field_values?.length) {
			const isSameCustomField = searchedProduct.custom_field_values.some((field) => (
				productToMatch.custom_field_values?.some((customField) => (
					getIsSameCustomField(field, customField)
				))));

			return isSameProduct && isSameCustomField;
		}

		return isSameProduct;
	},
);

export const prepareCartItemsForCheckoutInitiation = (products: EcommerceProduct[]): PreparedCartItem[] => products.map((product) => {
	const {
		// eslint-disable-next-line camelcase
		custom_fields: customFields,
		...productClone
	} = product;

	if (customFields?.length) {
		return {
			...productClone,
			custom_field_values: customFields.map((field) => ({
				custom_field_id: field.id,
				value: field.value || '',
			})),
		};
	}

	return productClone;
});

export const getQuantifiedCartItems = (cartItems: PreparedCartItem[]) => cartItems
	.reduce((quantifiedProducts: EcommerceCartPayloadItem[], product: PreparedCartItem) => {
		const existingProduct = getExistingProductWithCustomField(quantifiedProducts, product);

		if (existingProduct) {
			return quantifiedProducts.map((productToUpdate) => {
				const isSameProduct = productToUpdate.variant_id === product.variants[0].id;

				const isSameCustomFields = productToUpdate.custom_field_values?.every(
					(field) => product.custom_field_values?.some(
						(productField) => productField.custom_field_id === field.custom_field_id && productField.value === field.value,
					),
				) ?? true;

				if (isSameProduct && isSameCustomFields) {
					return {
						variant_id: productToUpdate.variant_id,
						quantity: productToUpdate.quantity + 1,
						custom_field_values: productToUpdate.custom_field_values,
					};
				}

				return productToUpdate;
			});
		}

		return [
			...quantifiedProducts,
			{
				custom_field_values: product.custom_field_values,
				variant_id: product.variants[0].id,
				quantity: 1,
				time_slot: product.variants[0].booking_event?.time_slot,
				time_zone: product.variants[0].booking_event?.time_zone,
			},
		];
	}, []);

export const getSuccessUrl = (products: EcommerceProduct[]) => {
	const isProductDigital = products.some((product) => product.type.value === EcommerceProductType.DIGITAL);
	const isProductDonation = products.some((product) => product.type.value === EcommerceProductType.DONATION);
	const isProductBooking = products.some((product) => product.type.value === EcommerceProductType.BOOKING);
	let productTypeQuery = '';

	if (isProductBooking) {
		productTypeQuery = `&product=${EcommerceProductType.BOOKING}`;
	} else if (isProductDonation) {
		productTypeQuery = `&product=${EcommerceProductType.DONATION}`;
	} else if (isProductDigital) {
		productTypeQuery = `&product=${EcommerceProductType.DIGITAL}`;
	}

	return `${window.location.origin}?open-modal=EcommerceCheckoutSuccess${productTypeQuery}`;
};
