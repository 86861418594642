import StoreApi from '@zyro-inc/site-modules/api/StoreApi';
import { loadStripeSdk } from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { ECOMMERCE_PARCEL_MACHINE_PROVIDERS } from '@zyro-inc/site-modules/constants/ecommerce';
import {
	EcommerceCartUpdatePayload,
	EcommerceRegion,
	EcommerceShippingProviderType,
	StripeAddressEvent,
	StripeConfirmEvent,
	StripeShippingRate,
	StripeShippingRateEvent,
} from '@zyro-inc/site-modules/types';
import {
	computed,
	ComputedRef,
	ref,
} from 'vue';

type StripeElement = {
	create: (type: string, options: any) => any;
	update: (options: any) => void;
	mount: (element: HTMLElement) => void;
	on: (event: string, handler: (event: any) => void) => void;
};

type Stripe = {
	elements: (payload: {
		mode?: string,
		currency?: string,
		amount?: number,
		onBehalfOf?: string | null,
	}) => StripeElement,
	confirmPayment: (options: {
		// clientSecret: string,
		elements: StripeElement,
		confirmParams: { return_url: string }
	}) => Promise<{ error?: Error }>
};

export const getShippingAddressDetails = (event: StripeAddressEvent | StripeConfirmEvent, isProductPhysical: boolean) => {
	if (isProductPhysical && 'address' in event) {
		return {
			shippingAddress: event.address,
			shippingCountryCode: event.address.country?.toLowerCase(),
			shippingStateCode: event.address.state
				? `${event.address.country.toLowerCase()}-${event.address.state.toLowerCase()}`
				: undefined,
			shippingName: event.name,
		};
	}

	const confirmEvent = event as StripeConfirmEvent;

	if (!isProductPhysical || !confirmEvent.shippingAddress) {
		return {};
	}

	const shippingAddress = confirmEvent.shippingAddress.address;
	const shippingCountryCode = shippingAddress.country?.toLowerCase();
	const shippingStateCode = shippingAddress.state ? `${shippingCountryCode}-${shippingAddress.state?.toLowerCase()}` : undefined;
	const shippingName = confirmEvent.shippingAddress.name;

	return {
		shippingAddress,
		shippingCountryCode,
		shippingStateCode,
		shippingName,
	};
};

export const getBillingAddressDetails = (event: StripeAddressEvent | StripeConfirmEvent, isProductPhysical: boolean) => {
	// shipping address is not returned when product is non-physical so we will receive only billingDetails on conforim event
	if (isProductPhysical && !('billingDetails' in event)) {
		return {
			billingAddress: event.address,
			billingCountryCode: event.address.country?.toLowerCase(),
			billingStateCode: event.address.state
				? `${event.address.country?.toLowerCase()}-${event.address.state.toLowerCase()}`
				: undefined,
			billingName: event.name,
		};
	}

	const billingAddress = (event as StripeConfirmEvent).billingDetails.address;
	const billingCountryCode = billingAddress.country.toLowerCase();
	const billingStateCode = billingAddress.state ? `${billingCountryCode}-${billingAddress.state.toLowerCase()}` : undefined;

	return {
		billingAddress,
		billingCountryCode,
		billingStateCode,
		billingName: (event as StripeConfirmEvent).billingDetails.name,
	};
};

export const useExpressCheckout = ({
	isProductPhysical,
	updateCartData,
	currencyCode,
	productPrice,
}: {
	productPrice: ComputedRef<number>,
	isProductPhysical: ComputedRef<boolean>,
	updateCartData?: (payload: EcommerceCartUpdatePayload) => Promise<void>,
	currencyCode: ComputedRef<string>,
}) => {
	const isStripeMounted = ref(false);
	const stripe = ref<Stripe>(null as unknown as Stripe);
	const elements = ref<StripeElement>(null as unknown as StripeElement);
	const expressCheckoutElement = ref<any>(null);
	const shippingRates = ref<StripeShippingRate[]>([]);
	const selectedShippingRate = ref<StripeShippingRate | null>(null);
	const selectedRegion = ref<EcommerceRegion>();

	const totalPriceAmount = computed(() => productPrice.value + (selectedShippingRate.value?.amount || 0));

	const setSelectedShippingRate = (rate: StripeShippingRate) => {
		selectedShippingRate.value = rate;
	};

	const getShippingRates = async (cartId?: string) => {
		if (!isProductPhysical.value || !cartId) {
			return;
		}

		try {
			const shippingOptions = await StoreApi.getCartShippingOptions(cartId);

			shippingRates.value = shippingOptions
				.filter((option) => !(ECOMMERCE_PARCEL_MACHINE_PROVIDERS as EcommerceShippingProviderType[]).includes(option.provider));
			[selectedShippingRate.value] = shippingRates.value;
		} catch (error) {
			console.error(error);
		}
	};

	const updateStripeElement = ({ price }: { price: number}) => {
		elements.value?.update({
			amount: price,
		});
	};

	const setupStripeElements = async (
		stripeAccountId: string,
		stripePublicKey: string,
		expressCheckoutElementContainerRef: HTMLElement | null,
	) => {
		try {
			await loadStripeSdk();
			// @ts-ignore
			// eslint-disable-next-line no-undef
			stripe.value = Stripe(stripePublicKey);

			elements.value = stripe.value.elements({
				mode: 'payment',
				currency: currencyCode.value,
				amount: totalPriceAmount.value,
				onBehalfOf: stripeAccountId,
			});

			expressCheckoutElement.value = elements.value.create('expressCheckout', {
				layout: {
					maxColumns: 1,
					maxRows: 3,
				},
			});

			if (!expressCheckoutElementContainerRef) {
				return;
			}

			expressCheckoutElement.value.mount(expressCheckoutElementContainerRef);

			isStripeMounted.value = true;
		} catch (error) {
			console.error('Error setting up Stripe:', error);
		}
	};

	const handleAddressChangeEvent = async (
		event: StripeAddressEvent | StripeConfirmEvent,
		regions?: EcommerceRegion[],
		cartId?: string,
	) => {
		const {
			shippingAddress,
			shippingCountryCode,
			shippingStateCode,
			shippingName,
		} = getShippingAddressDetails(event, isProductPhysical.value);

		if (isProductPhysical.value) {
			selectedRegion.value = regions?.find(
				(region) => region.countries.find((country) => {
					const foundCountry = country.countryCode === shippingCountryCode;

					if (country.provinces?.length) {
						const foundState = country.provinces.find((provinceCode) => provinceCode === shippingStateCode);

						return !!foundCountry && !!foundState;
					}

					return !!foundCountry;
				}),
			);

			if (!selectedRegion.value) {
				if ('reject' in event) {
					event.reject();
				} else {
					event.paymentFailed({
						reason: 'invalid_shipping_address',
					});
				}

				return;
			}
		}

		const {
			billingAddress,
			billingCountryCode,
			billingStateCode,
			billingName,
		} = getBillingAddressDetails(event, isProductPhysical.value);

		const shippingAddressToUpdate = {
			...shippingAddress,
			country: shippingCountryCode,
			state: shippingStateCode,
			name: shippingName,
			line1: `${shippingAddress?.line1} ${shippingAddress?.line2}`,
		};
		const billingAddressToUpdate = {
			...billingAddress,
			country: billingCountryCode,
			state: billingStateCode,
			name: billingName,
			line1: billingAddress ? `${billingAddress.line1} ${billingAddress.line2}` : undefined,
		};

		try {
		// TODO: replace these two calls with one - updateCartInfo since it will return shipping options, https://hostingers.atlassian.net/browse/BE-782
			await updateCartData?.({
				shippingAddress: shippingAddressToUpdate,
				billingAddress: billingAddressToUpdate,
				regionId: selectedRegion.value?.id,
			});
			await getShippingRates(cartId);
		} catch (error) {
			if ('reject' in event) {
				event.reject();
			} else {
				event.paymentFailed({
					reason: 'invalid_shipping_address',
				});
			}
		}
	};

	const handleShippingRateChange = (event: StripeShippingRateEvent) => {
		selectedShippingRate.value = event.shippingRate;

		updateStripeElement({
			price: totalPriceAmount.value,
		});
	};

	return {
		expressCheckoutElement,
		elements,
		stripe,
		totalPriceAmount,
		shippingRates,
		selectedRegion,
		getShippingRates,
		handleAddressChangeEvent,
		selectedShippingRate,
		setSelectedShippingRate,
		handleShippingRateChange,
		setupStripeElements,
		updateStripeElement,
		isStripeMounted,
	};
};
