import {
	EcommerceCartPayloadCustomField,
	EcommerceProduct,
	EcommerceProductCustomField,
} from '@zyro-inc/site-modules/types';

import { ECOMERCE_PRODUCTS_FREE_TYPES } from '@zyro-inc/site-modules/constants/ecommerce';

export const getLowestPriceVariant = (product: EcommerceProduct) => product.variants.reduce((acc, curr) => {
	const accPrice = acc.prices[0]?.sale_amount || acc.prices[0]?.amount;
	const currPrice = curr.prices[0]?.sale_amount || curr.prices[0]?.amount;

	return accPrice < currPrice ? acc : curr;
});

export const getHighestPriceVariant = (product: EcommerceProduct) => product.variants.reduce((acc, curr) => {
	const accPrice = acc.prices[0]?.sale_amount || acc.prices[0]?.amount;
	const currPrice = curr.prices[0]?.sale_amount || curr.prices[0]?.amount;

	return accPrice > currPrice ? acc : curr;
});

export const getIsSameCustomField = (
	customField: EcommerceProductCustomField | EcommerceCartPayloadCustomField,
	customFieldToMatch: EcommerceProductCustomField | EcommerceCartPayloadCustomField,
) => {
	const customFieldId = 'id' in customField ? customField.id : customField.custom_field_id;
	const customFieldToMatchId = 'id' in customFieldToMatch ? customFieldToMatch.id : customFieldToMatch.custom_field_id;

	return customFieldId === customFieldToMatchId && customField.value === customFieldToMatch.value;
};

export const getIsFreeProductTypeFree = (product: EcommerceProduct) => ECOMERCE_PRODUCTS_FREE_TYPES
	.includes(product.type.value) && !product.variants[0].prices[0].amount;
