import { SiteData } from '@hostinger/builder-schema-validator/schema/schemaTypes';
import { getPageIdFromPath } from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';

export const generateHrefLangLinks = ({
	locales,
	canonicalUrl,
	siteData,
}: {
    locales: string[];
    canonicalUrl: string;
    siteData: SiteData;
}) => [
	{
		href: canonicalUrl,
		hreflang: 'x-default',
		rel: 'alternate',
	},
	...locales.flatMap((locale) => {
		const url = new URL(canonicalUrl);
		const pageId = getPageIdFromPath({
			siteData,
			path: url.pathname,
		});
		const pagePath = getPagePathFromId({
			pageId,
			siteData,
			locale,
		});

		// If page is not found in current locale, do not add hreflang link
		if (!pagePath) {
			return [];
		}

		return {
			href: `${url.origin}${pagePath}`,
			hreflang: locale,
			rel: 'alternate',
		};
	}),
];
